import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

// Cookie Script
const scriptElement = document.createElement("script");
scriptElement.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
scriptElement.type = "text/javascript";
scriptElement.setAttribute("charset", "UTF-8");
// Stage
// scriptElement.setAttribute(
//   "data-domain-script",
//   "018e84ec-9f4b-7fa1-9078-be3ca6c12b6e-test"
// )

// Production
scriptElement.setAttribute(
  "data-domain-script",
  "0190fe18-bb29-71f6-86c9-a5081e90cb39"
);

// Cookie Button
const cookieButton = document.createElement("button");
cookieButton.id = "ot-sdk-btn";
cookieButton.className = "ot-sdk-show-settings";
cookieButton.innerText = "Cookie Settings";

export const onClientEntry = () => {
  window.onload = () => {
    // Cookie Script
    document.head.appendChild(scriptElement);
    // Cookie Button
    // document.body.appendChild(cookieButton);
  };
};